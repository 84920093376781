::-webkit-scrollbar {
    height: 2px;
    width: 2px;
}
::-webkit-scrollbar-track {
    background: transparent;   
}
::-webkit-scrollbar-thumb {
    background: #888; 
    height: 238px;
}
::-webkit-scrollbar-thumb:horizontal {
    background: black;
    border-radius: 3px;
}
::-webkit-scrollbar-track:horizontal {
    background:  #E5E5E5;   
    border-radius: 3px;
}
.firefox-scrollbar {
    scrollbar-color: #888 transparent;
    scrollbar-width: thin;
}