* {
  box-sizing: border-box;
}

body {
  margin: 0;
  background-color: #f8f8f8;
}

html {
  font-size: 12px;
}

html,
body,
#root {
  height: 100%;
  overflow-x: hidden;
}

button:hover {
  cursor: pointer;
}

#email::placeholder, #password::placeholder {
  color: #241C1C;
  font-weight: 400;
  font-family: Open Sans;
}

input:focus {
  outline: 1px solid #000000;
}
