@media print {
  body, html, #page-container, .scrollable-page, .ps, .panel {
    width: 100% !important;
   
}
  .noPrint{
    display: none;
  }
 
  @page {

    size: landscape;
    width: 100% !important;
    min-height: 100%;
    height: 100% !important;
    margin-left: 7%;
    margin-right: 3%;
    margin-top:15%;
    margin-bottom: 15%;

    
  }
}